
import { defineComponent } from "vue";
import store from "@/store";
import { AudioUtils, getCurrencyIcon } from "@/utils";
import { CLOSE_BONUS_IN_BONUS_MODAL } from "@/constants";
import PlayGame from "@/components/Panels/PlayGame.vue";
import { MultiButtonMode } from "@/store/ui";
import MobileButton from "@/components/UI/MobileButton.vue";

export default defineComponent({
  name: "modal-buyin",
  components: {
    PlayGame,
    MobileButton,
  },
  data() {
    return {
      applyTimer: undefined as number | undefined,
    };
  },
  computed: {
    isBuyInModal(): boolean {
      return store.state.ui.showBuyinModal;
    },
    currency(): number {
      return store.state.userData.currency;
    },
    currencyIcon(): string {
      return getCurrencyIcon(this.currency);
    },
    isGambusaki(): boolean {
      return store.state.currentGameId === "loto-gambusaki-gold-9";
    },

    buttonMode(): MultiButtonMode {
      return store.state.ui.multiButtonMode;
    },
    isMaintenance(): boolean {
      return store.state.session.maintenance;
    },
    isBetstop(): boolean {
      return store.state.session.betstop;
    },
    disconnected(): boolean {
      return !store.state.connected;
    },
    isGoldButton(): boolean {
      return store.state.currentGameId === "loto-gambusaki-gold-9";
    },
    buyInMultiplier(): number {
      return store.state.settings.buy_in.ticket_cost_multiplier;
    },
    sumAmount(): number | undefined {
      return store.state.createTicket.sumAmount;
    },
    showSumAmount(): string | null {
      return this.sumAmount ? ((this.sumAmount / 100) * this.buyInMultiplier).toFixed(2) : null;
    },
    makeTicketDisabled(): boolean {
      return store.state.ui.makeTicketDisabled;
    },
  },
  methods: {
    leave(el: HTMLElement, done: Function) {
      // add transition classes to modal content
      const x = el.firstElementChild;
      if (x) {
        x.classList.add("slide-leave-active", "slide-leave-to");
      }
      setTimeout(() => {
        done();
      }, 250);
    },

    hideBuyinModal() {
      store.commit("setShowBuyinModal", false);
    },
    makeBuyInTicket() {
      AudioUtils.playSound("button-press-sound", 0, 1);
      store.commit("setMakeTicketDisabled", true);
      store.dispatch("makeBuyInTicket");
      store.commit("setShowBuyinModal", false);
    },
    startBuyInAutogame() {
      store.commit("setMakeTicketDisabled", true);
      store.commit("setAutoGame", true);
      AudioUtils.playSound("button-press-sound", 0, 1);
      store.commit("setShowBuyinModal", false);
      store.dispatch("makeBuyInTicket");
    },
  },
  watch: {},
});
