
import { defineComponent, PropType } from "vue";
//import HighlightingLines from "@/components/Scoreboards/HighlightingLines.vue";
import FruitsGoldCell from "@/components/Scoreboards/FruitsGoldCell.vue";
//import CarpetNice from "@/components/Scoreboards/CarpetNice.vue";
import store from "@/store";
import router from "@/router";
import { CarpetAnim } from "@/store/ui";

export default defineComponent({
  name: "fruits-gold-scoreboard",
  components: { FruitsGoldCell },
  data() {
    return {
      // cloudsOpen: require("@/images/backgrounds/clouds-open.webp"),
      // cloudsClose: require("@/images/backgrounds/clouds-closed.webp"),
      // cloudsKey: 0,
    };
  },
  props: {
    animationIndexes: {
      type: Array as PropType<number[]>,
      default: [],
    },
    scoreboard: {
      type: Array,
      default: new Array(15),
    },
    isWon: Boolean,
  },
  beforeCreate() {
    const isDoubling = store.state.doubling.isOn;
    const isMobile = store.state.uiVersion === "mobile";
    const isMevahoGold = store.state.currentGameId === "loto-funny-fruits";
    if (isDoubling && isMobile && isMevahoGold) {
      router.push("/doubling");
    } else if (isDoubling && !isMevahoGold) {
      router.push("/doubling");
    }
  },
  mounted() {},
  computed: {
    styles(): object {
      return {
        "--close": this.showAnimation === "close",
        "--open": this.showAnimation === "open",
      };
    },
    showAnimation(): CarpetAnim | undefined {
      return store.state.ui.showAnimation;
    },
    // currentClouds(): string {
    //   return this.showAnimation === "open"
    //     ? this.cloudsClose
    //     : this.showAnimation === "close"
    //     ? this.cloudsOpen
    //     : "";
    // },
  },
  methods: {
    isGlow(idx: number): boolean {
      return this.animationIndexes.includes(idx);
    },
    /*  resetAnimation() {
      const img = this.$refs.cloudsImage as HTMLImageElement | undefined;
      if (img) {
        img.style.animation = "none";
        img.offsetHeight;
        img.style.animation = "";
      }
    },*/
  },
  watch: {},
});
