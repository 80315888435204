import store, { ACtx } from "@/store";
import State from "./state";
import { SessionInfoUpdate } from "@/types/session";

export type SessionState = {
  sessionID: string;
  betstop: boolean;
  maintenance: boolean;
};

export const state: SessionState = {
  sessionID: "",
  betstop: false,
  maintenance: false,
};

export const mutations = {
  setSessionId(state: State, id: string) {
    state.session.sessionID = id;
  },
  setBetstop(state: State, value: boolean) {
    state.session.betstop = value;
  },
  setMaintenance(state: State, value: boolean) {
    state.session.maintenance = value;
  },
};

export const actions = {
  async updateSessionInfo({ commit, state, dispatch }: ACtx, data: SessionInfoUpdate) {
    if (data.sessionID) {
      commit("setSessionId", data.sessionID);
    }

    const maintenance = data.maintenance;
    const betstop = data.betstop;

    if (maintenance !== undefined) {
      commit("setMaintenance", maintenance);

      if (maintenance) {
        commit("setAutoGame", false);
        // Закрыть модалку betstop, если она была открыта
        if (state.modals.betstop) {
          await dispatch("closeModal", "betstop");
        }
        await dispatch("toggleModal", "maintenance");
        return; // Прекращение выполнения кода, если maintenance активен
      } else {
        await dispatch("closeModal", "maintenance");
      }
    }

    if (betstop !== undefined) {
      commit("setBetstop", betstop);
      if (betstop) {
        commit("setAutoGame", false);
        await dispatch("toggleModal", "betstop");
      } else {
        await dispatch("closeModal", "betstop");
      }
    }
  },
};

export const getters = {};
