import moment from "moment";
import "moment/locale/ru";

import { createI18n, LocaleMessages } from "vue-i18n";

function loadLocaleMessages(): LocaleMessages<any> {
  const locales = require.context("./locales", true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages: LocaleMessages<any> = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

const i18n = createI18n({
  legacy: false,
  locale: process.env.VUE_APP_I18N_LOCALE || "ru",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "ru",
  messages: loadLocaleMessages(),
});

export default i18n;

export function seti18nLocale(newLocale: string, fallbackLocale?: string) {
  const locale = resolveLocale(newLocale, fallbackLocale);
  if (locale !== newLocale) {
    console.debug(`Set fallback locale ${locale}, ${newLocale} not supported`);
  } else {
    console.debug(`Set locale ${locale}`);
  }

  if (locale.startsWith("en")) {
    i18n.global.locale.value = "en";
  }
  if (locale.startsWith("ru")) {
    i18n.global.locale.value = "ru";
  }
  if (locale.startsWith("tj")) {
    i18n.global.locale.value = "tj";
  }
  if (locale.startsWith("kz")) {
    i18n.global.locale.value = "kz";
  }
  //// set monent locale
  moment.locale(i18n.global.locale.value);
}

export function supportsLocale(newLocale: string): boolean {
  return i18n.global.availableLocales.includes(newLocale);
}

export function getLocale() {
  return i18n.global.locale.value;
}

export function resolveLocale(newLocale: string, fallbackLocale?: string): string {
  if (!supportsLocale(newLocale) && fallbackLocale && supportsLocale(fallbackLocale)) {
    return fallbackLocale;
  } else {
    return newLocale;
  }
}

(window as any)._SET_LOCALE_ = function (newLocale: string) {
  seti18nLocale(newLocale);
};
