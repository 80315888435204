
import { defineComponent } from "vue";
import MainContainer from "@/components/MainContainer.vue";
import MenuWrapper from "@/components/MenuWrapper.vue";
import MobileHeader from "@/components/Mobile/MobileHeader.vue";
import ScoreboardWrapper from "@/components/Scoreboards/ScoreboardWrapper.vue";
import PurchasePanel from "@/components/PurchasePanel.vue";
import SimpleInfo from "@/components/SimpleInfo.vue";
import store from "@/store";
import { getCurrencyIcon } from "@/utils";
import ControlPanel from "@/components/Panels/ControlPanel.vue";
import ModalBonus from "@/components/Modals/ModalBonus.vue";
import ModalPromo from "@/components/Modals/ModalPromo.vue";
import ModalBuyin from "@/components/Modals/ModalBuyin.vue";
import { MevahoMode } from "@/types/user";

export default defineComponent({
  name: "mobile",
  components: {
    ModalBonus,
    ControlPanel,
    SimpleInfo,
    PurchasePanel,
    ScoreboardWrapper,
    MobileHeader,
    MenuWrapper,
    MainContainer,
    ModalPromo,
    ModalBuyin,
  },
  data() {
    return {
      currentPamirBackground: 0,
      pamirStars: require("@/images/backgrounds/pamir-mobile-stars.webp"),
      pamirBackgrounds: [
        require("@/images/backgrounds/pamir-mobile-bg.webp"),
        require("@/images/backgrounds/pamir-legends-bonus-night-mobile.webp"),
      ],
      preloadedImages: [] as HTMLImageElement[],
    };
  },
  mounted() {
    this.preloadImages();
  },
  computed: {
    currency(): number {
      return store.state.userData.currency;
    },
    currencyIcon(): string {
      return getCurrencyIcon(this.currency);
    },
    isBetstop(): boolean {
      return store.state.session.betstop;
    },
    messageStatus(): string | undefined {
      return store.state.information.status;
    },
    lastPayout(): string | undefined {
      const payout = store.state.currentTicket && store.state.currentTicket.payout;
      return payout ? (payout / 100).toFixed(2) : undefined;
    },
    numBonusGames(): number {
      return store.state.bonus.numGames;
    },
    currentBonusGameCount(): number {
      return store.state.bonus.countGame;
    },
    promoIsOn(): boolean {
      return store.state.ui.promoIsOn;
    },
    promoCount(): number | undefined {
      return store.state.promo.promo?.quantity;
    },
    promoTicketsLeft(): number {
      return store.state.ui.promoTicketsLeft;
    },
    stepPayout(): string {
      return (store.state.ui.stepPayout / 100).toFixed(2);
    },
    currentPayout(): string {
      return store.state.currentTicket && store.state.currentTicket.payout
        ? (store.state.currentTicket.payout / 100).toFixed(2)
        : "0";
    },
    scoreboardMode(): MevahoMode {
      return store.state.userData.settings.mevaho_mode;
    },
    isSamePayout(): boolean {
      return this.currentPayout === this.stepPayout;
    },
    totalPayoutText(): any {
      return this.$t("payoutText");
    },
    isBonusGamePamirLegends(): boolean {
      return store.state.ui.showBonusPersons && store.state.currentGameId === "loto-pamir-legends";
    },
    isPamir(): boolean {
      return store.state.currentGameId === "loto-pamir-legends";
    },
    pamirBackgroundsStyle(): any {
      return {
        backgroundImage: `url(${this.pamirBackgrounds[this.currentPamirBackground]})`,
      };
    },
  },
  methods: {
    changeBackground() {
      this.currentPamirBackground = this.currentPamirBackground === 0 ? 1 : 0;
    },
    preloadImages() {
      if (this.isPamir) {
        // const images = [this.pamirDay, this.pamirNight, this.pamirStars];
        this.pamirBackgrounds.forEach((src) => {
          const img = new Image();
          img.src = src;
          this.preloadedImages.push(img);
        });
      }
    },
  },
  watch: {
    isBonusGamePamirLegends(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.changeBackground();
      } else if (!newVal && oldVal) {
        this.changeBackground();
      }
    },
  },
});
