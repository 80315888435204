import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Transition as _Transition, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5f6983e2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-buyin__container" }
const _hoisted_2 = { class: "modal-buyin__wrapper" }
const _hoisted_3 = { class: "modal-buyin__wrapper-amount" }
const _hoisted_4 = { class: "modal-buyin__buttons-container" }
const _hoisted_5 = { class: "modal-buyin__wrapper-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MobileButton = _resolveComponent("MobileButton")!

  return (_openBlock(), _createBlock(_Transition, {
    name: "container",
    onLeave: _ctx.leave,
    duration: "100",
    appear: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["modal-buyin", { ['--hidden']: !_ctx.isBuyInModal }])
      }, [
        _createVNode(_Transition, {
          name: "slide",
          duration: "100",
          appear: ""
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.showSumAmount), 1)
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_MobileButton, {
                    onClick: _ctx.makeBuyInTicket,
                    disabled: _ctx.isMaintenance || _ctx.isBetstop || _ctx.disconnected || _ctx.makeTicketDisabled,
                    text: 'Розыгрыш билета',
                    color: _ctx.isGoldButton ? 'gold' : 'yellow',
                    class: "modal-buyin__button-buyin"
                  }, null, 8, ["onClick", "disabled", "color"]),
                  _createVNode(_component_MobileButton, {
                    onClick: _ctx.startBuyInAutogame,
                    disabled: _ctx.isMaintenance || _ctx.isBetstop || _ctx.disconnected || _ctx.makeTicketDisabled,
                    text: 'Авторозыгрыш билетов',
                    color: _ctx.isGoldButton ? 'gold' : 'yellow',
                    class: "modal-buyin__button-buyin"
                  }, null, 8, ["onClick", "disabled", "color"])
                ]),
                _createVNode(_component_MobileButton, {
                  onClick: _ctx.hideBuyinModal,
                  disabled: _ctx.isMaintenance || _ctx.isBetstop || _ctx.disconnected,
                  text: _ctx.$t('cancelButton'),
                  color: 'yellow',
                  class: "modal-buyin__button"
                }, null, 8, ["onClick", "disabled", "text"])
              ])
            ])
          ]),
          _: 1
        })
      ], 2)
    ]),
    _: 1
  }, 8, ["onLeave"]))
}